<template>
    <div>
        <div class="">
            <a class="home-btn" @click="winback()" href="javascript:;"><i class="el-icon-s-home"></i> 返回主页</a>
        </div>
        <ul class="rank-ul" v-infinite-scroll="getData">
            <li class="list" v-for="(m, index) in list" @click.stop="showM(m,index)">
                <!-- <span class="rank">1</span> -->
                <img class="tx" :src="rImg(m.head_portrait)" :onerror="txImg" alt="">
                <div class="des-box">
                    <div class="name-box">
                        <p class="name">{{m.name}}<span class="boy-tag girl-tag">{{m.age}}</span></p>
                        <span class="score" v-html="dealScore(m.score_avg)"></span>
                    </div>
                    <div class="name-box">
                        <span class="num">{{m.scoring_count}}人已评</span>
                        <button class="btn" v-if="m.score=='未评分'">评分</button>
                        <span class="my" v-else>{{m.score}}</span>
                    </div>
                </div>
            </li>
            <p class="more-btn" v-if="list.length">{{(total==-1 || list.length %10 !=0 ) ? '暂无更多':'加载中'}}</p>
        </ul>
        <div class="modal-bg" v-if="modal.active!=-1" @click="modal.active=-1">
            <div class="modal-box">
                <div class="modal-content" @click.stop>
                    <div class="head" style="text-align: right;">
                        <i class="iconfont icon-guanbi" @click.stop="modal.active=-1"></i>
                    </div>
                    <div class="name-box flex-c">为 <p class="name flex"><img :src="rImg(list[modal.active].head_portrait)" class="avatar" :onerror="txImg" alt="">{{list[modal.active].name}}<span class="boy-tag girl-tag">{{list[modal.active].age}}</span></p> 评分 </div>
                    <div class="score-box">
                        <div class="score">{{modal.score}}.0</div>
                        <div class="flex-c">
                            <div class="star" ref="star" @touchstart="touchmoveF($event);" @touchmove="touchmoveF($event);">
                                <div class="star-no"></div>
                                <div class="star-active" :style="'width:'+(modal.score*10)+'%'"></div>
                            </div>
                        </div>
                    </div>
                    <el-button round type="primary" @click="submitM()">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                list: [],
                modal: {
                    left: 0,
                    width: 0,
                    score: 10,
                    active: -1
                },
                total: 0
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.getData(1)

        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // ...mapMutations([])
            // 获取列表
            async getData(first) {
                let param = {
                    pagesize: 10
                };
                if (first) {
                    param.page = 1;
                }
                console.log(param)
                let {
                    list,
                    total
                } = await this.$y_list('api/v2.pageants/currentUser', this.list, param);
                this.list = list;
                this.total = total;
            },
            // 处理分数
            dealScore(s) {
                var s2 = s.split('.');
                return `${s2[0]}.<span class="s-score">${s2[1]}</span>`
            },
            // 显示评分弹窗
            showM(m, i) {
                if (m.score == '未评分') {
                    this.modal.active = i
                }
            },
            // 评分
            submitM() {
                var _this = this;
                var a = _this.modal.active;
                var m = _this.list[a];
                if (!m) return;
                this.$y_confirm({
                    msg: '只能给她评分一次，确定给这个分数吗？'
                }, async (bi) => {
                    if (bi == 1) {
                        let ret = await _this.$y_ajax('api/v2.pageants/scoring', {
                            touser_id: m.user_id,
                            score: _this.modal.score
                        })
                        _this.$y_msg(ret.msg);
                        if (ret.code == 200) {
                            var total = m.score_avg * m.scoring_count + _this.modal.score;
                            m.scoring_count++;
                            _this.list[a].score_avg = (total / m.scoring_count).toFixed(1);
                            _this.list[a].score = _this.modal.score;

                            _this.modal.active = -1;
                        }
                    }
                })
            },
            touchmoveF(event) {
                var x = event.changedTouches[0].pageX;
                var _this = this;
                if (!_this.modal.left) {
                    _this.modal.left = _this.$refs.star.offsetLeft;
                    _this.modal.width = _this.$refs.star.offsetWidth;
                }
                var w = (x - _this.modal.left) / _this.modal.width;
                if (w < 0.2) {
                    w = 0.2;
                } else if (w > 1) {
                    w = 1;
                }
                var s = parseInt(w * 10);
                if (s % 2 != 0) {
                    // 接口分数限制为 2 - 4 - 6 - 8 - 10
                    s = s + 1;
                }
                _this.modal.score = s;
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .home-btn {
        padding: 10px;
        display: inline-block;
    }

    .rank-ul .list {
        padding: 10px;
        display: flex;
        align-items: center;
        background-image: url('../../assets/rank_item_bg.png');
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        margin: 10px;
        border-radius: 10px;
        filter: blur(10);
    }

    .rank-ul .rank {
        min-width: 30px;
        text-align: center;
        font-style: italic;
        font-size: 20px;
        margin-right: 7px;
        color: orangered;
    }

    .rank-ul .tx {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .rank-ul .des-box {
        flex: 1;
    }

    .rank-ul .name-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .rank-ul .my {
        font-size: 0.6;
        color: #49C265;
    }

    .rank-ul .num {
        color: #aaa;
        font-size: 0.6rem;
    }

    .rank-ul .btn {
        border-radius: 15px;
        padding: 3px 10px;
        background: #49C265;
        color: #fff;
        border: 0;
        outline: 0;
    }

    .modal-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .modal-box {
        background-color: #fff;
        background-image: url('../../assets/star_bg.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 85vw;
        border-radius: 10px;
    }

    .modal-content {
        padding: 10px;
        text-align: center;
    }

    .modal-content .icon-guanbi {
        font-size: 1rem;
    }

    .modal-content .name-box {
        color: #49C265;
    }

    .modal-content .name {
        margin: 0 10px;
    }

    .modal-content .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .modal-content .score-box {
        margin: 30px 0;
    }

    .modal-content .score {
        font-size: 1.5rem;
        color: #49C265;
    }

    .modal-content .star {
        position: relative;
        margin-top: 15px;
    }

    .modal-content .star-no {
        background-image: url('../../assets/heart.png');
        background-repeat: repeat-x;
        background-position: left center;
        background-size: 40px;
        width: 200px;
        height: 40px;
        filter: grayscale(1);
    }

    .modal-content .star-active {
        background-image: url('../../assets/heart.png');
        background-repeat: repeat-x;
        background-position: left center;
        background-size: 40px;
        width: 100%;
        height: 40px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .modal-content .new-btn {
        margin-top: 20px;
    }

    .more-btn {
        text-align: center;
        color: #aaa;
        padding: 15px 0;
    }
</style>